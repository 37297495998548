<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="mt-1">
    <b-card>
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" v-if="materialId > 0">Material Edit</th>
              <th scope="col" v-else>Material Add</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-form>
        <b-row>
          <b-col cols="12" sm="6" md="4" xl="4">
            <b-form-group label="Name" label-for="name">
              <b-form-input required id="name" type="text" v-model="materialValue.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col cols="12">
            <b-button v-if="materialId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
              Save
            </b-button>
            <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>
<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';
import router from '@/router';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const MATERIAL_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      materialValue: {
        name: '',
      },

      loading: false,
      materialId: null,
    };
  },

  watch: {},

  mounted() {
    this.getMaterialById();
  },

  methods: {
    getMaterialById() {
      this.loading = true;

      if (router.currentRoute.params.id) {
        this.materialId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('settings/getMaterialById', { id: router.currentRoute.params.id })
          .then((res) => {
            this.materialValue = res.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.loading = false;
      }
    },

    formSubmitted() {
      this.loading = true;
      if (!this.materialValue.name) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please fill in all fields.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        this.loading = false;
        return;
      }

      if (this.materialId == null) {
        store
          .dispatch('settings/saveMaterial', this.materialValue)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Material Add Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'settings-materials' });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        store
          .dispatch('settings/updateMaterial', this.materialValue)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Material Update Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'settings-materials' });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
